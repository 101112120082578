// Students.js
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import './research.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ResearchDashboard = () => {
    useEffect(() => {
        const elements = document.getElementsByClassName("text-yellow");

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add(".text-dark1");
        }
        const section = document.getElementsByClassName("mainSectionDiv")[0];
        section.classList.add("researchSectionActive");
    }, []);

    return (
        // <>

        <div className=''>
            {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
            {/* <> */}
            <Navbar />
            <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-research'>
                <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
                    <section>
                        <h4 className='mb-3 custom-large-heading section-margin'>Research</h4>
                        <hr className='mt-5'></hr>
                    </section>
                    <div className="row text-yellow pt-2 gx-5 img-spacing">
                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1001756" className="text-decoration-none" target="_blank">
                            <img src='images/Computerized cognitive training in cognitively healthy older adults.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"  ></img>
                            <div className='lh-lg mt-3'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Lampit, A., Hallock, H., & Valenzuela, M. (2014).</span><br></br>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Computerized cognitive training in cognitively healthy older adults: A systematic review and meta-analysis of effect modifiers.</span><br></br>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">PLoS Medicine, 11(11), e1001756</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.1037/a0037507" className="text-decoration-none" target="_blank">
                            <img src='images/Video game training enhances cognition of older adults.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Toril, P., Reales, J. M., & Ballesteros, S. (2014).</span><br></br>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Video game training enhances cognition of older adults: A meta-analytic study.</span> <br></br>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Psychology and Aging, 29(3), 706-716 </span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.3389/fnhum.2012.00183" className="text-decoration-none" target="_blank">
                            <img src='images/Brain training in progress_A review of trainability in healthy seniors.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Buitenweg, J. I., Murre, J. M., & Ridderinkhof, K. R. (2012).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Brain training in progress: A review of trainability in healthy seniors.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Frontiers in Human Neuroscience, 6, 183</span>
                            </div>
                        </a>
                    </div>
                </div>
{/* ----------------------------------------------------------------------- */}

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://jamanetwork.com/journals/jamainternalmedicine/fullarticle/1673747" className="text-decoration-none" target="_blank">
            <img src='images/The Mental Activity and eXercise (MAX) trial.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Barnes, D. E., Santos-Modesitt, W., Poelke, G., Kramer, A. F., Castro, C., Middleton, L. E., & Yaffe, K. (2013).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">The Mental Activity and eXercise (MAX) trial: A randomized controlled trial to enhance cognitive function in older adults.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">JAMA Internal Medicine, 173(9), 797-804.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://www.nature.com/articles/nrn2298" className="text-decoration-none" target="_blank">
            <img src='images/Be smart, exercise your heart.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Hillman, C. H., Erickson, K. I., & Kramer, A. F. (2008).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Be smart, exercise your heart: Exercise effects on brain and cognition.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Nature Reviews Neuroscience, 9, 58-65.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://www.pnas.org/content/111/23/8410" className="text-decoration-none" target="_blank">
            <img src='images/Active learning increases student performance in science, engineering, and mathematics.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Freeman, S., Eddy, S. L., McDonough, M., Smith, M. K., Okoroafor, N., Jordt, H., & Wenderoth, M. P. (2014).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Active learning increases student performance in science, engineering, and mathematics.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Proceedings of the National Academy of Sciences, 111(23), 8410-8415.</span>
            </div>
        </a>
    </div>
</div>


<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://pubmed.ncbi.nlm.nih.gov/10945922/" className="text-decoration-none" target="_blank">
            <img src='images/The unity and diversity of executive functions and their contributions to complex frontal lobe tasks.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Miyake, A., Friedman, N. P., Emerson, M. J., Witzki, A. H., Howerter, A., & Wager, T. D. (2000).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">The unity and diversity of executive functions and their contributions to complex 'frontal lobe' tasks: A latent variable analysis.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Cognitive Psychology, 41, 49-100.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://pubmed.ncbi.nlm.nih.gov/26271508/" className="text-decoration-none" target="_blank">
            <img src='images/Neural basis of thinking_ Laboratory problems versus real-world problems.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Goel, Vinod. (2010).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Neural basis of thinking: Laboratory problems versus real-world problems.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Wiley Interdisciplinary Reviews: Cognitive Science. 1. 613 - 621.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://www.researchgate.net/publication/284355198_Lifelong_learning_as_an_instrument_for_human_capital_development_in_Benin" className="text-decoration-none" target="_blank">
            <img src='images/Lifelong learning as an instrument for human capital development in Benin.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Biao, Idowu. (2015).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Lifelong learning as an instrument for human capital development in Benin.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">International Review of Education. 61.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jgs.12541" className="text-decoration-none" target="_blank">
            <img src='images/Criterion and convergent validity of the Montreal cognitive assessment with screening and standardized neuropsychological testing.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Lam, B., Middleton, L. E., Masellis, M., Stuss, D. T., Harry, R. D., Kiss, A., & Black, S. E. (2013).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Criterion and convergent validity of the Montreal cognitive assessment with screening and standardized neuropsychological testing.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Journal of the American Geriatrics Society, 61(12), 2181-2185.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://journals.sagepub.com/doi/full/10.1177/095679761245966" className="text-decoration-none" target="_blank">
            <img src='images/The impact of sustained engagement on cognitive function in older adults_The Synapse Project.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Park, D. C., Lodi-Smith, J., Drew, L., Haber, S., Hebrank, A., Bischof, G. N., & Aamodt, W. (2014).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">The impact of sustained engagement on cognitive function in older adults: The Synapse Project.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Psychological Science, 25, 103-112.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://pubmed.ncbi.nlm.nih.gov/15564054/" className="text-decoration-none" target="_blank">
            <img src='images/Neurofeedback with anxiety and affective disorders.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Hammond, D. C. (2005).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Neurofeedback with anxiety and affective disorders.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Child and Adolescent Psychiatric Clinics, 14, 105-123.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://ila.onlinelibrary.wiley.com/doi/abs/10.1598/RRQ.46.2.4" className="text-decoration-none" target="_blank">
            <img src='images/Neuroscience and reading_ A review for reading education researchers.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Hruby, G. G., & Goswami, U. (2011).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Neuroscience and reading: A review for reading education researchers.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Reading Research Quarterly, 46(2), 156-172.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://pubmed.ncbi.nlm.nih.gov/19014238/" className="text-decoration-none" target="_blank">
            <img src='images/Applying the science of learning to the design of multimedia instruction.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Mayer, R. E. (2008).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Applying the science of learning: Evidence-based principles for the design of multimedia instruction.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">American Psychologist, 63(8), 760-769.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://journals.sagepub.com/doi/10.3102/0034654307313795" className="text-decoration-none" target="_blank">
              <img src='images/Focus on formative feedback.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              <div className='d-flex flex-column mt-3 lh-lg'>
                  <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Shute, V. J. (2008).</span>
                  <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Focus on formative feedback.</span>
                  <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Review of Educational Research, 78, 153-189.</span>
              </div>
          </a>
      </div>
  </div>


<div className="col-md-6 col-lg-4 my-4">
   <div className="card border-0 text-yellow bg-transparent">
       <a href="https://www.tandfonline.com/doi/abs/10.1207/s15430421tip4102_2" className="text-decoration-none" target="_blank">
           <img src='images/Becoming a self-regulated learner.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
           <div className='d-flex flex-column mt-3 lh-lg'>
               <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Zimmerman, B. J. (2002).</span>
               <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Becoming a self-regulated learner: An overview.</span>
               <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Theory Into Practice, 41(2), 64-70.</span>
           </div>
       </a>
   </div>
</div>



<div className="col-md-6 col-lg-4 my-4">
   <div className="card border-0 text-yellow bg-transparent">
       <a href="https://pubmed.ncbi.nlm.nih.gov/22352812/" className="text-decoration-none" target="_blank">
           <img src='images/Psychological correlates of university students academic performance.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
           <div className='d-flex flex-column mt-3 lh-lg'>
               <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Richardson, M., Abraham, C., & Bond, R. (2012).</span>
               <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Psychological correlates of university students' academic performance: A systematic review and meta-analysis.</span>
               <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Psychological Bulletin, 138(2), 353-387.</span>
           </div>
       </a>
   </div>
</div> 

<div className="col-md-6 col-lg-4 my-4">
   <div className="card border-0 text-yellow bg-transparent">
       <a href="https://www.amazon.com/Differentiated-Classroom-Responding-Needs-Learners/dp/0871203421" className="text-decoration-none" target="_blank">
           <img src='images/The differentiated classroom_ Responding to the needs of all learners .webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
           <div className='d-flex flex-column mt-3 lh-lg'>
               <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Tomlinson, C. A. (2014).</span>
               <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">The differentiated classroom: Responding to the needs of all learners (2nd ed.).</span>
               <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Alexandria, VA: ASCD. ISBN: 978-0871203427</span>
           </div>
       </a>
   </div>
</div> 

<div className="col-md-6 col-lg-4 my-4">
   <div className="card border-0 text-yellow bg-transparent">
       <a href="https://doi.org/10.1038/s41598-021-83456-x" className="text-decoration-none" target="_blank">
           <img src='images/Enhancing learning in a perceptual cognitive training paradigm using EEG-neurofeedback.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
           <div className='d-flex flex-column mt-3 lh-lg'>
               <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Parsons, B., & Faubert, J. (2021).</span>
               <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Enhancing learning in a perceptual-cognitive training paradigm using EEG-neurofeedback.</span>
               <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Scientific Reports, 11, 4061</span>
           </div>
       </a>
   </div>
</div>  


<div className="col-md-6 col-lg-4 my-4">
   <div className="card border-0 text-yellow bg-transparent">
       <a href="https://doi.org/10.3389/fnagi.2021.682683" className="text-decoration-none" target="_blank">
           <img src='images/Neurofeedback and the Aging Brain_ A Systematic Review of Training Protocols for Dementia.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
           <div className='d-flex flex-column mt-3 lh-lg'>
               <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Trambaiolli, L. R., Cassani, R., Mehler, D. M. A., & Falk, T. H. (2021).</span>
               <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Neurofeedback and the Aging Brain: A Systematic Review of Training Protocols for Dementia and Mild Cognitive Impairment.</span>
               <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Frontiers in Aging Neuroscience, 13</span>
           </div>
       </a>
   </div>
</div>  


<div className="col-md-6 col-lg-4 my-4">
   <div className="card border-0 text-yellow bg-transparent">
       <a href="https://www.sciencedirect.com/science/article/pii/S0035378721006974" className="text-decoration-none" target="_blank">
           <img src='images/Neurofeedback for cognitive enhancement and intervention and brain plasticity.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
           <div className='d-flex flex-column mt-3 lh-lg'>
               <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Loriette, C., Ziane, C., Ben Hamed (2021).</span>
               <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Neurofeedback for cognitive enhancement and intervention and brain plasticity.</span>
               <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Revue Neurologique, Volume 177, Issue 9, Pages 1133-1144</span>
           </div>
       </a>
   </div>
</div>





<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.3389/fnhum.2016.00301" className="text-decoration-none" target="_blank">
            <img src='images/The Do s and Donts of Neurofeedback Training_A Review of the Controlled Studies Using Healthy Adults.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Rogala, J., Jurewicz, K., Paluch, K., Kublik, E., Cetnarski, R., & Wróbel, A. (2016).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">The Do's and Don'ts of Neurofeedback Training: A Review of the Controlled Studies Using Healthy Adults.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Frontiers in Human Neuroscience, 10, 301</span>
            </div>
        </a>
    </div>
</div>


<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.1016/j.clinph.2013.05.020" className="text-decoration-none" target="_blank">
            <img src='images/Neurofeedback training improves attention and working memory performance.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Wang, J. R., & Hsieh, S. (2013).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Neurofeedback training improves attention and working memory performance.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Clinical Neurophysiology, 124(12), 2406-2420</span>
            </div>
        </a>
    </div>
</div>


<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.1016/j.neubiorev.2013.09.015" className="text-decoration-none" target="_blank">
            <img src='images/EEG neurofeedback for optimising performance I_ A review of cognitive and affective outcome in healthy participants.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Gruzelier, J. H. (2014).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">EEG-neurofeedback for optimising performance. I: A review of cognitive and affective outcome in healthy participants.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Neuroscience & Biobehavioral Reviews, 44, 124-141</span>
            </div>
        </a>
    </div>
</div>


<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.3389/fnhum.2013.00695" className="text-decoration-none" target="_blank">
            <img src="images/Learning to modulate one's own brain activity_ The effect of spontaneous mental strategies.webp" alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Kober, S. E., Witte, M., Ninaus, M., Neuper, C., & Wood, G. (2013).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Learning to modulate one's own brain activity: The effect of spontaneous mental strategies.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Frontiers in Human Neuroscience, 7, 695</span>
            </div>
        </a>
    </div>
</div>


<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.1080/01411926.2010.501096" className="text-decoration-none" target="_blank">
            <img src='images/Exploring inclusive pedagogy.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Florian, L., & Black-Hawkins, K. (2011).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Exploring inclusive pedagogy.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">British Educational Research Journal, 37(5), 813-828.</span>
            </div>
        </a>
    </div>
</div>


<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://eric.ed.gov/?id=EJ979712" className="text-decoration-none" target="_blank">
            <img src='images/Critical components of successful inclusion of students with severe disabilities.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Alquraini, T., & Gut, D. (2012).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Critical components of successful inclusion of students with severe disabilities: Literature review.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">International Journal of Special Education, 27, 42-59.</span>
            </div>
        </a>
    </div>
</div>


<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.1038/nrn3817" className="text-decoration-none" target="_blank">
            <img src='images/Neuroscience and education_ myths and messages.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Howard-Jones, P. A. (2014).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Neuroscience and education: myths and messages.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Nature Reviews Neuroscience, 15(12), 817-824.</span>
            </div>
        </a>
    </div>
</div>


<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://pubmed.ncbi.nlm.nih.gov/31202633/" className="text-decoration-none" target="_blank">
            <img src='images/Artificial intelligence in medical education_ Best practices using machine learning to assess surgical expertise in virtual reality simulation.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Winkler-Schwartz, A., et al. (2019).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Artificial intelligence in medical education: Best practices using machine learning to assess surgical expertise in virtual reality simulation.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Journal of surgical education, 76(6), 1681-1690.</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.1037/a0031882" className="text-decoration-none" target="_blank">
                            <img src='images/Using adaptive learning technologies to personalize instruction to student interests_ The impact of relevant contexts on performance and learning outcomes.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Walkington, C. (2013).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Using adaptive learning technologies to personalize instruction to student interests: The impact of relevant contexts on performance and learning outcomes.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Journal of Educational Psychology, 105(4), 932-945</span>
                            </div>
                        </a>
                    </div>
                </div>


<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://eric.ed.gov/?id=EJ1061765" className="text-decoration-none" target="_blank">
                            <img src='images/Note Taking Interventions to Assist Students with Disabilities in Content Area Classes.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Boyle, Joseph R.; Forchelli, Gina A.; Cariss, Kaitlyn (2015)</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Note-Taking Interventions to Assist Students with Disabilities in Content Area Classes.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Preventing School Failure, v59 n3 p186-195</span>
                            </div>
                        </a>
                    </div>
                </div>

<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.1111/jcal.12172" className="text-decoration-none" target="_blank">
                            <img src='images/Review of computer based assessment for learning in elementary and secondary education.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Shute, V. J., & Rahimi, S. (2017).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Review of computer-based assessment for learning in elementary and secondary education.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Journal of Computer Assisted Learning, 33, 1-19</span>
                            </div>
                        </a>
                    </div>
                </div>

<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.3102/0013189X12459803" className="text-decoration-none" target="_blank">
                            <img src='images/A systemic view of implementing data literacy in educator preparation.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Mandinach, E. B., & Gummer, E. S. (2013).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">A systemic view of implementing data literacy in educator preparation.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Educational Researcher, 42, 30-37</span>
                            </div>
                        </a>
                    </div>
                </div>

<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.3389/fpsyg.2021.633066" className="text-decoration-none" target="_blank">
                            <img src='images/The Dilemma of Inclusive Education_ Inclusion for Some or Inclusion for All .png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Leijen, Ä., Arcidiacono, F., & Baucal, A. (2021).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">The Dilemma of Inclusive Education: Inclusion for Some or Inclusion for All.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Frontiers in Psychology, 12</span>
                            </div>
                        </a>
                    </div>
                </div>

<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.3389/fpsyg.2021.661427" className="text-decoration-none" target="_blank">
                            <img src='images/How Inclusive Interactive Learning Environments Benefit Students Without Special Educational Needs.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Kirschner, P. A., & van Merriënboer, J. J. G. (2021).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">How Inclusive Interactive Learning Environments Benefit Students Without Special Educational Needs.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Frontiers in Psychology, 12</span>
                            </div>
                        </a>
                    </div>
                </div>

<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://eric.ed.gov/?id=EJ1099986" className="text-decoration-none" target="_blank">
                            <img src='images/Effective Practice in Inclusive and Special Needs Education.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Buli-Holmberg, Jorun; Jeyaprathaban, Sujathamalini (2016)</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Effective Practice in Inclusive and Special Needs Education.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">International Journal of Special Education, v31 n1 p119-134 2016</span>
                            </div>
                        </a>
                    </div>
                </div>

<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.1080/01587919.2018.1553562" className="text-decoration-none" target="_blank">
                            <img src='images/Predicting student satisfaction and perceived learning within online learning environments.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Alqurashi, E. (2019).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Predicting student satisfaction and perceived learning within online learning environments.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Distance Education, 40, 133-148</span>
                            </div>
                        </a>
                    </div>
                </div>

               
<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.1177/0162643420914624" className="text-decoration-none" target="_blank">
                            <img src='images/Assistive Technology for Students With Disabilities_ An Updated Snapshot.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Bouck, E. C., & Long, H. (2021).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Assistive Technology for Students With Disabilities: An Updated Snapshot.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Journal of Special Education Technology, 36(4), 249–257</span>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://www.researchgate.net/publication/276274580_Supporting_content_learning_through_technology_for_K-12_students_with_disabilities_Document_No_IC-10" className="text-decoration-none" target="_blank">
                            <img src='images/Supporting content learning through technology for K-12 students with disabilities.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Israel, Maya & Marino, Matthew & Delisio, Lauren & Serianni, Barbara. (2014).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Supporting content learning through technology for K-12 students with disabilities (Document No. IC-10)</span>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://www.tandfonline.com/doi/abs/10.1080/00461520.2010.515933" className="text-decoration-none" target="_blank">
                            <img src='images/Self regulated learning in learning environments with pedagogical agents that interact in natural language.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Graesser, A. C., & McNamara, D. S. (2010).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Self-regulated learning in learning environments with pedagogical agents that interact in natural language.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Educational Psychologist, 45(4), 234-244</span>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://www.sciencedirect.com/science/article/abs/pii/S0959475211000806" className="text-decoration-none" target="_blank">
                            <img src='images/Dynamics of affective states during complex learning.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">D'Mello, S., & Graesser, A. (2012).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Dynamics of affective states during complex learning.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Learning and Instruction, 22(2), 145-157</span>
                            </div>
                        </a>
                    </div>
                </div>

                {/* <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://pubmed.ncbi.nlm.nih.gov/24125857/" className="text-decoration-none" target="_blank">
                            <img src='images/Brain Training 1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Gruzelier, J. H. (2014).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">EEG-neurofeedback for optimising performance.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Neuroscience & Biobehavioral Reviews, 44, 124-141</span>
                            </div>
                        </a>
                    </div>
                </div> */}

                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://www.amazon.com/Accelerated-Learning-21st-Century-Master-Mind/dp/0440507790" className="text-decoration-none" target="_blank">
                            <img src='images/Accelerated learning for the 21st century_ The six-step plan to unlock your master-mind.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Rose, C., & Nicholl, M. J. (1997).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Accelerated learning for the 21st century: The six-step plan to unlock your master-mind.</span>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://www.amazon.com/Multimedia-Learning-Richard-Mayer/dp/0521735351" className="text-decoration-none" target="_blank">
                            <img src='images/Multimedia Learning.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Source: Mayer, R. E. (2009).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Multimedia learning (2nd ed.).</span>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.1186/s41239-021-00292-9" className="text-decoration-none" target="_blank">
                            <img src='images/The impact of artificial intelligence on learner instructor interaction in online learning.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Seo, K., Tang, J., Roll, I., Fels, S., & Yoon, D. (2021).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">The impact of artificial intelligence on learner–instructor interaction in online learning.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">International Journal of Educational Technology in Higher Education, 18, 54</span>
                            </div>
                        </a>
                    </div>
                </div>





<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.1016/j.caeai.2021.100025" className="text-decoration-none" target="_blank">
            <img src='images/AI technologies for education Recent research and future directions.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Zhang, K., & Aslan, A. B. (2021).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">AI technologies for education: Recent research & future directions.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Computers and Education: Artificial Intelligence, 2, 100025</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.1007/s40593-016-0110-3" className="text-decoration-none" target="_blank">
            <img src='images/Evolution and revolution in artificial intelligence in education.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Roll, I., & Wylie, R. (2016).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Evolution and revolution in artificial intelligence in education.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">International Journal of Artificial Intelligence in Education, 26(2), 582-599</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://www.tcpress.com/culturally-responsive-teaching-9780807758762" className="text-decoration-none" target="_blank">
            <img src='images/Culturally responsive teaching_ Theory research and practice.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Gay, G. (2018).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Culturally responsive teaching: Theory, research, and practice.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Teachers College Press</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://www.biblio.com/9781119369608" className="text-decoration-none" target="_blank">
            <img src='images/Empowered educators_ How high performing systems shape teaching quality around the world.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Darling-Hammond, L. (2017).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Empowered educators: How high-performing systems shape teaching quality around the world.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">John Wiley & Sons</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://www.chegg.com/textbooks/affirming-diversity-the-sociopolitical-context-of-multicultural-education-7th-edition-what-s-new-in-foundations-intro-to-teaching-7th-edition-9780134047232-0134047230" className="text-decoration-none" target="_blank">
            <img src='images/Affirming diversity_ The sociopolitical context of multicultural education.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Nieto, S., & Bode, P. (2018).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Affirming diversity: The sociopolitical context of multicultural education.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Pearson</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://www.amazon.com/Intercultural-Competence-Higher-Education-Internationalization/dp/1138693855" className="text-decoration-none" target="_blank">
            <img src='images/Intercultural competence in higher education_ International approaches, assessment and application.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Deardorff, D. K., & Arasaratnam-Smith, L. A. (Eds.). (2017).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Intercultural competence in higher education: International approaches, assessment and application.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Routledge</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="http://www.edchange.org/publications/Equity-Literacy-for-All.pdf" className="text-decoration-none" target="_blank">
            <img src='images/Equity literacy for all.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Gorski, P. C., & Swalwell, K. (2015).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Equity literacy for all.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Educational Leadership, 72(6), 34-40</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://www.amazon.com/Culturally-Sustaining-Pedagogies-Teaching-Learning/dp/0807758337" className="text-decoration-none" target="_blank">
            <img src='images/Culturally sustaining pedagogies_ Teaching and learning for justice in a changing world.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Paris, D., & Alim, H. S. (Eds.). (2017).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Culturally sustaining pedagogies: Teaching and learning for justice in a changing world.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Teachers College Press</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://ijme-journal.org/index.php/ijme/article/view/1663" className="text-decoration-none" target="_blank">
            <img src='images/Multicultural education past present and future Struggles for dialog and power sharing.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Sleeter, C. E. (2018).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Multicultural education past, present, and future: Struggles for dialog and power-sharing.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">International Journal of Multicultural Education, 20, 5-20.</span>
            </div>
        </a>
    </div>
</div> 

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://eric.ed.gov/?id=EJ1125963" className="text-decoration-none" target="_blank">
            <img src='images/Facilitating and bridging out of class and in class learning_ An interactive e book based flipped learning approach for math courses.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Hwang, G. J., & Lai, C. L. (2017).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Facilitating and bridging out-of-class and in-class learning: An interactive e-book-based flipped learning approach for math courses.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Educational Technology & Society, 20, 184-197</span>
            </div>
        </a>
    </div>
</div> 

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.1016/j.compedu.2017.04.010" className="text-decoration-none" target="_blank">
            <img src='images/Examining the students behavioral intention to use e-learning in Azerbaijan.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Chang, Jeyhun Hajiyev, Chia-Rong Su,</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Examining the students behavioral intention to use e-learning in Azerbaijan. The General Extended Technology Acceptance Model for E-learning approach.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Computers & Education, Volume 111, (2017)</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.1111/bjet.12152" className="text-decoration-none" target="_blank">
            <img src='images/Ethical and privacy principles for learning analytics.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Pardo, A., & Siemens, G. (2014).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Ethical and privacy principles for learning analytics.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">British Journal of Educational Technology, 45(3), 438-450</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://er.educause.edu/-/media/files/article-downloads/erm1151.pdf" className="text-decoration-none" target="_blank">
            <img src='images/Penetrating the fog_ Analytics in learning and education.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Siemens, G., & Long, P. (2011).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Penetrating the fog: Analytics in learning and education.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">EDUCAUSE Review, 46(5), 30-32</span>
            </div>
        </a>
    </div>
</div>

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://www.amazon.com/Universal-Design-Higher-Education-Principles/dp/1891792903" className="text-decoration-none" target="_blank">
            <img src='images/Universal design in higher education_ From principles to practice.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Burgstahler, S. (2015).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Universal design in higher education: From principles to practice.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Harvard Education Press</span>
            </div>
        </a>
    </div>
</div>

{/* <div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.1038/nrn3817" className="text-decoration-none" target="_blank">
            <img src='images/Brain Training 2.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Howard-Jones, P. A. (2014).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Neuroscience and education: Myths and messages.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Nature Reviews Neuroscience, 15(12), 817-824</span>
            </div>
        </a>
    </div>
</div> */}

<div className="col-md-6 col-lg-4 my-4">
    <div className="card border-0 text-yellow bg-transparent">
        <a href="https://doi.org/10.1016/j.susoc.2022.05.004" className="text-decoration-none" target="_blank">
            <img src='images/Understanding the role of digital technologies in education.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
            <div className='d-flex flex-column mt-3 lh-lg'>
                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Asim, M., & Suman, R. (2022).</span>
                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Understanding the role of digital technologies in education: A review.</span>
                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Sustainable Operations and Computers, 3, 275-285</span>
            </div>
        </a>
    </div>
</div>


<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.3991/ijet.v12i11.7460" className="text-decoration-none" target="_blank">
                            <img src='images/Factors affecting the adoption of elearning management systems in higher education institutions.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Alzahrani, A. I. (2017).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Factors affecting the adoption of e-learning management systems in higher education institutions.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">International Journal of Emerging Technologies in Learning (iJET), 12(11), 94-107</span>
                            </div>
                        </a>
                    </div>
                </div> 


<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.14569/IJACSA.2014.050120" className="text-decoration-none" target="_blank">
                            <img src='images/Using the technology acceptance model in understanding academics behavioural intention to use learning management systems.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Alharbi, S., & Drew, S. (2014).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Using the technology acceptance model in understanding academics’ behavioural intention to use learning management systems.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">International Journal of Advanced Computer Science and Applications, 5, 143-155</span>
                            </div>
                        </a>
                    </div>
                </div> 



<div className="col-md-6 col-lg-4 my-4">
                    <div className="card border-0 text-yellow bg-transparent">
                        <a href="https://doi.org/10.1016/j.chb.2016.05.013" className="text-decoration-none" target="_blank">
                            <img src='images/Sharing instructors experience of learning management system A technology perspective of user satisfaction in distance learning course.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                            <div className='lh-lg mt-3 d-flex flex-column'>
                                <span className="scroll-item mb-1 text-yellow  common-textshadow montser-sml-LightItalic">Almarashdeh, I. (2016).</span>
                                <span className="scroll-item mb-1  text-yellow montser-lrg-bold-re  ">Sharing instructors' experience of learning management system: A technology perspective of user satisfaction in distance learning course.</span>
                                <span className="scroll-item mb-1  text-yellow montser-sml-LightItalic">Computers in Human Behavior, 63, 249-255</span>
                            </div>
                        </a>
                    </div>
                </div>

{/* ----------------------------------------------------------------------- */}


                <div className="col-12">
                    <hr className='mt-8rem'></hr>
                </div>
            </div>
                </div>
                <Footer />
            </div>
            {/* </> */}
        </div>
        // </div>
        // </>


    );
};

export default ResearchDashboard;

